import styled from 'styled-components';

import { Text } from '@soluto-private/mx-asurion-ui-react';

import asurionTechCareLogo from '../assets/asuriontechcare-logo.svg';

const Action = styled.div`
  font-size: 0.875rem;
  line-height: 1.15;
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--aui-action-on-filled-action);
  padding: var(--aui-spacing-100)
    calc(var(--aui-spacing-100) + var(--aui-spacing-050)) var(--aui-spacing-100)
    calc(var(--aui-spacing-100) + var(--aui-spacing-050));
  border-radius: var(--aui-action-border-radius);
  overflow: hidden;
  gap: var(--aui-spacing-050);
  background: var(--aui-action-filled-action);
`;

const TextContainer = styled.div`
  flex: 1;
`;

const Image = styled.img`
  display: block;
  width: 150px;
  height: 24px;
`;

export const CollapsedHeaderContent = () => {
  return (
    <>
      <TextContainer>
        <Image alt="asurion tech care" src={asurionTechCareLogo} />
        <Text as="h1" size={1}>
          We simply out-care the rest
        </Text>
      </TextContainer>
      <Action className="partner-dark">Get started</Action>
    </>
  );
};
