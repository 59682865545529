import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';

import { GlobalVendorScrollbarResetRule } from '@service-initiation/shared-ui-scrollbar-reset';
import { WidgetAnalyticsProvider } from '@service-initiation/widget-data-access-analytics';
import { InteractionModelProvider } from '@service-initiation/widget-data-access-interaction-model';

import App from './app/app';
import { techCareModel } from './initializations';

declare global {
  interface Window {
    _fs_run_in_iframe: boolean;
  }
}

window['_fs_run_in_iframe'] = true;

ReactDOM.render(
  <StrictMode>
    <GlobalStyles />
    <GlobalVendorScrollbarResetRule />
    <InteractionModelProvider model={techCareModel}>
      <WidgetAnalyticsProvider>
        <App />
      </WidgetAnalyticsProvider>
    </InteractionModelProvider>
  </StrictMode>,
  document.getElementById('root')
);
