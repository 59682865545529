import {
  Bumper,
  useBumperContext,
} from '@service-initiation/widget-features-bumper';

import asurionShuffle from '../assets/asurion-avatar-shuffle.lottie';
import { CollapsedHeaderContent } from './CollapsedHeaderContent';
import { ExpandedHeaderContent } from './ExpandedHeaderContent';

export const HeaderContent = () => {
  const { expanded } = useBumperContext();

  return (
    <>
      <Bumper.AvatarShufflePlayer src={asurionShuffle} />
      {expanded ? <ExpandedHeaderContent /> : <CollapsedHeaderContent />}
    </>
  );
};
