import styled from 'styled-components';

import { Icon, Text, colors } from '@soluto-private/mx-asurion-ui-react';

import starRating from '../assets/star-rating.svg';

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: flex-start;
`;

const Image = styled.img`
  display: block;
  height: 9px;
`;

export const ExpandedHeaderContent = () => {
  return (
    <>
      <TextContainer>
        <Text as="h1" size={2}>
          Tech care powered by experts
        </Text>
        <Image alt="star rating" src={starRating} />
      </TextContainer>
      <Icon
        fill={colors.white}
        size="large"
        src="chevron-down"
        title="asurion"
      />
    </>
  );
};
