import {
  AuiToken,
  useApplyAuiTokenClass,
} from '@service-initiation/shared-utils-hooks';
import { Bumper } from '@service-initiation/widget-features-bumper';
import { ChatWindow } from '@service-initiation/widget-features-chat-window';

import { HeaderContent } from './HeaderContent';
import './app.css';

const theme = {
  colors: {
    primary: 'linear-gradient(106deg, #6700b8 -0.59%, #a90ad3 100%)',
  },
};

export function App() {
  useApplyAuiTokenClass(document.body, AuiToken.AsurionLight);

  return (
    <Bumper theme={theme}>
      <Bumper.Header>
        <HeaderContent />
      </Bumper.Header>
      <Bumper.Body>
        <Bumper.Panel>
          <ChatWindow />
        </Bumper.Panel>
      </Bumper.Body>
    </Bumper>
  );
}

export default App;
