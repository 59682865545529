import {
  type InteractionNode,
  WidgetComponentType,
} from '@service-initiation/widget-data-access-interaction-model';
import { Partners } from '@service-initiation/widget-data-access-partners';
import { VariantOptions } from '@service-initiation/widget-ui-chat-bubble';

import { partnerInfoConfig } from './partnerInfoConfig';

export const techCareModel: InteractionNode[] = [
  {
    children: undefined,
    content:
      'Select your provider or retailer to learn more about your tech care options',
    id: 1,
    messageVariant: VariantOptions.FLAT,
    type: WidgetComponentType.MESSAGE,
  },
  {
    children: [
      {
        children: undefined,
        content: "Ok, you've selected Verizon as your existing provider",
        id: 2,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 3,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${partnerInfoConfig[Partners.VERIZON].phoneNumber}`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 4,
        partner: Partners.VERIZON,
        phone: partnerInfoConfig[Partners.VERIZON].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Verizon',
    id: 5,
    partner: Partners.VERIZON,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content: "Ok, you've selected AT&T as your existing provider",
        id: 6,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 7,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${partnerInfoConfig[Partners.ATT].phoneNumber}`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 8,
        partner: Partners.ATT,
        phone: partnerInfoConfig[Partners.ATT].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'AT&T',
    id: 9,
    partner: Partners.ATT,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content: "Ok, you've selected NRG as your existing provider",
        id: 10,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 11,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${partnerInfoConfig[Partners.NRG].phoneNumber}`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 12,
        partner: Partners.NRG,
        phone: partnerInfoConfig[Partners.NRG].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'NRG',
    id: 13,
    partner: Partners.NRG,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content:
          "Ok, you've selected Asurion Appliance+ as your existing provider",
        id: 14,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 15,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${
          partnerInfoConfig[Partners.APPLIANCE_PLUS].phoneNumber
        }`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 16,
        partner: Partners.APPLIANCE_PLUS,
        phone: partnerInfoConfig[Partners.APPLIANCE_PLUS].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'Asurion Appliance+',
    id: 17,
    partner: Partners.APPLIANCE_PLUS,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content: "Ok, you've selected uBreakiFix by Asurion as your retailer",
        id: 18,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 19,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${partnerInfoConfig[Partners.UBIF].phoneNumber}`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 20,
        partner: Partners.UBIF,
        phone: partnerInfoConfig[Partners.UBIF].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: 'uBreakiFix by Asurion',
    id: 21,
    partner: Partners.UBIF,
    type: WidgetComponentType.SERVICE,
  },
  {
    children: [
      {
        children: undefined,
        content:
          'No problem! Our experts can still help you find the right tech care',
        id: 22,
        messageVariant: VariantOptions.ROUND,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: 'Call to learn more',
        id: 23,
        messageVariant: VariantOptions.FLAT,
        type: WidgetComponentType.MESSAGE,
      },
      {
        children: undefined,
        content: `Call ${partnerInfoConfig[Partners.NONE].phoneNumber}`,
        dataAnalyticsId: 'CallCTA',
        iconSrc: 'phone',
        id: 24,
        partner: Partners.NONE,
        phone: partnerInfoConfig[Partners.NONE].phoneNumber,
        type: WidgetComponentType.SERVICE,
      },
    ],
    content: "I don't know",
    id: 25,
    type: WidgetComponentType.SERVICE,
  },
];
