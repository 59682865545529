import { Partners } from '@service-initiation/widget-data-access-partners';

type PartnerConfig = {
  phoneNumber: string;
};

export const partnerInfoConfig: {
  [key: string]: PartnerConfig;
} = {
  [Partners.APPLIANCE_PLUS]: {
    phoneNumber: '888-451-0552',
  },
  [Partners.ATT]: {
    phoneNumber: '888-451-0552',
  },
  [Partners.NONE]: {
    phoneNumber: '888-451-0552',
  },
  [Partners.NRG]: {
    phoneNumber: '888-451-0552',
  },
  [Partners.UBIF]: {
    phoneNumber: '888-451-0552',
  },
  [Partners.VERIZON]: {
    phoneNumber: '888-451-0552',
  },
};
